.contact-box{
    display: grid;
    align-items: center;
    align-self: center;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19);
    width: 100%;
}

.left{
    background-size: cover;

}

.right{

}

.field{
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0);
    outline: none;
    background-color: rgba(230, 230, 230, 0.6);
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    margin-bottom: 22px;
    transition: .3s;
}

.field:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

textarea{
    min-height: 150px;
}

.btn{
    width: 50%;
    padding: 0.5rem 1rem;
    color: #fff;
    font-size: 1.1rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.btn:hover{
    background-color: darkblue;
}

.field:focus{
    border: 2px solid rgba(30,85,250,0.47);
    background-color: #fff;
}


@media screen and (max-width: 880px){
    .contact-box{
        grid-template-columns: 1fr;
    }
    .right{
        padding: 5px;
    }
    .field {

    }
    textarea{
        min-height: 100px;
    }
}


