.footer {
    display: flex;
    flex-direction: column;

    font-weight: bold;
    max-height: 140px;
}
.try2 {

}

.try2:nth-child(1n) {
    animation: mytry2 3.5s ease-in-out alternate-reverse infinite;
    animation-fill-mode: forwards;

}

.try2:nth-child(2n) {
    animation: mytry 3.5s ease-in-out alternate infinite;
    animation-fill-mode: forwards;


}

.try2:nth-child(3n) {
    animation: mytry3 4s ease-in-out alternate-reverse infinite;
    animation-fill-mode: forwards;


}

.try2:nth-child(4n) {
    animation: mytry4 3s ease-in-out alternate infinite;
    animation-fill-mode: forwards;


}

@keyframes mytry {
    0% { width: 90%; }
    100% { width: 100%; }
}


@keyframes mytry2 {
    0% { width: 85%; }
    100% { width: 100%; }
}

@keyframes mytry3 {
    0% { width: 75%; }
    100% { width: 100%; }
}

@keyframes mytry4 {
    0% { width: 70%; }
    100% { width: 100%; }
}

.footer-social-media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.hoch {
    position: relative;
    top: -40px;
}

.footer-social-media__icon {
    margin: 0px 4px;
    width: 25px;
    height: 25px;
}

.footer-info  {
    display: flex;
    justify-content: center;
}

.footer-info-left, .footer-info-center, .footer-info-right {
    color: white;
    font-size: small;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 30%;
}

.footer-info__name, .footer-info__email, .footer-info__number {
    margin-bottom: 10px;
}

.footer-info__name, .footer-info__returns {
    text-align: start;
}

.footer-info__email, .footer-info__terms {
    text-align: center;
}

.footer-info__number, .footer-info__contact {
    text-align: end;
}

.footer-date {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-seperator {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: black;
    width: 100%;
}

.footer-name {
    display: flex;
    justify-content: center;
}

.footer-bottom__name {
    text-transform: uppercase;
    font-size: large;
    margin: 0px 20px;
}
