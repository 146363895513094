.person-box{
    display: grid;
    align-items: center;
    align-self: center;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
}
.pic {
width: 1024px;
    height: 180px;
}


.schief {
    perspective: 600px;

}

.schief1 {
    transform: rotateY(25deg);

}

.back {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 110%;
    width: 110%;
    position: relative;
    margin-left: 180px;
}


@media screen and (max-width: 1820px){
    .back{
        margin-left: 120px;
    }
}

.videoscrolldiv {
    overflow-x: auto;
    width: 100%;


}

.videoscroll {
    max-height: 400px;
    width: 100%;
    max-width: 300px;

}


.vid {
   justify-content: center;
    align-items: center;
    display: inline-block;
    max-width: 160px!important;
    max-height: 180px!important;
}

.left{
    background-size: cover;

}

.right{
    padding: 25px 40px;
}
.person-info {
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19);


}

@media screen and (max-width: 1536px){
    .person-box{
        grid-template-columns: 1fr;
    }
    .left{

    }
}
