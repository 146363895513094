.nav-scrolled {
    background: whitesmoke;
    max-height: 100px;
    opacity: 99%;
    color: black;
    box-shadow: 0px 3px 3px lightgrey;
}

.nav-scrolled a {
    color: black;
    outline-color: blue;
    outline-offset: 4px;
    border-color: black;
}

.nav-scrolled nav {
    color:black;
    border-color: black;
}

.nav-top {

}

.svg {
   width: 100%;
}

.curved {
background-color: #f1f5f9;
}

.mobile-menu {
    padding-bottom: 6px;
    justify-content: right;
    color: black;
    opacity: 80%;
}

.mobile-menu.show {
    display: block;
}

.menu-scrolled {
    background: whitesmoke;
    opacity: 99%;
    color: black;
}

@media screen and (max-width: 450px) {
    .logo {

        min-width: 120px;
        min-height: 62px;

    }
    .mobile-button {

    }
}


